<template>
  <div class="box-modal-wrapper add-equipment-modal white-bg reserved-area-modal">
    <div class="container">
      <div class="box-modal-header with-bg">
        <div class="d-flex justify-content-center align-items-center">
          <button type="button" class="close-modal-btn" @click="closeAddExercisesModal">
            <closeIcon/>
          </button>
          <div class="box-modal-header-title">
            <p>ADICIONAR EQUIPAMENTO</p>
          </div>
        </div>
      </div>
      <div class="box-modal-body">
        <FormulateForm class="d-flex justify-content-between flex-column" v-model="values" @submit="addEquipments">
          <div class="reserved-area-form-group-wrapper">
            <div class="reserved-area-form-group">
              <sliderFilter/>
              <div class="reserved-area-workout-list">
                <div class="reserved-area-exercise-item" v-for="(item,index) in items" :key="index" :class="[ item.media.general ? '' : 'no-image']">
                  <label>
                    <div class="d-flex justify-content-between align-items-start">
                      <div class="reserved-area-workout-info-wrapper d-flex justify-content-start align-items-start">
                        <div class="reserved-area-workout-image">
                          <img :src="item.media.general[0].icon" alt="" v-if="item.media.general">
                        </div>
                        <div class="reserved-area-workout-info">
                          <p class="reserved-area-workout-title">{{ item.title }}</p>
                        </div>
                      </div>
                      <div class="reserved-area-exercise-item-checkbox">
                        <input type="checkbox" :value="item.id" v-model="selectedEquipmentIDs">
                      </div>
                    </div>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div class="reserved-area-form-btn-wrapper d-flex justify-content-center align-items-center">
            <button type="submit" class="reserved-area-form-btn generic-btn red-gradient ">Adicionar Exercícios</button>
          </div>
        </FormulateForm>
      </div>
    </div>
  </div>
</template>

<script>
import sliderFilter from "@/components/slider-filter.vue";
import clock from "@/components/svg/clock.vue";
import level from "@/components/svg/level.vue";
import fire from "@/components/svg/fire.vue";
import closeIcon from "@/components/svg/close.vue";
import axios from "axios";

import {EventBus} from "@/event-bus";

export default {
  name: "add-equipment-modal",
  data() {
    return {
      lang: this.$root.siteLang,
      items: [],
      values: {},
      selectedEquipmentIDs: [],
      selectedEquipment: [],
    }
  },
  components : {
    sliderFilter,
    clock,
    level,
    fire,
    closeIcon
  },
  methods: {
    closeAddExercisesModal() {
      document.getElementsByClassName("add-equipment-modal")[0].classList.remove('open');
    },
    addEquipments() {
      this.selectedEquipment = this.items.filter((item) => this.selectedEquipmentIDs.includes(item.id));

      EventBus.$emit('bus-selectedEquipment', this.selectedEquipment, this.selectedEquipmentIDs);

      this.closeAddExercisesModal();
    }
  },
  mounted() {
    axios
        .get(process.env.VUE_APP_API_URL + 'areas/6/items?type=cpc&with_category_title=0&with_products=0&lang=' + this.lang)
        .then((response) => {
          this.items = response.data;
        })
        .catch((e) => {
          this.errors.push(e);
        }).finally((e) => {});
  },
  created () {
    let self = this;

    EventBus.$on('bus-removeEquipment', function(id) {
      let elementID = id;
      let removeIndex = self.selectedEquipmentIDs.findIndex( item => item === elementID );
      self.selectedEquipmentIDs.splice( removeIndex, 1 );
    });
  }
}
</script>